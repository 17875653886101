// extracted by mini-css-extract-plugin
export var active = "HireDeveloper-module--active--b3675";
export var btn = "HireDeveloper-module--btn--00174";
export var card = "HireDeveloper-module--card--b68a4";
export var cardBody = "HireDeveloper-module--cardBody--11936";
export var head = "HireDeveloper-module--head--50861";
export var header = "HireDeveloper-module--header--b614d";
export var leftCard = "HireDeveloper-module--leftCard--284c5";
export var rightCard = "HireDeveloper-module--rightCard--40d47";
export var ripped = "HireDeveloper-module--ripped--0c44e";
export var section = "HireDeveloper-module--section--00522";
export var serviceTitleCard = "HireDeveloper-module--serviceTitleCard--93745";
export var servicesContainer = "HireDeveloper-module--servicesContainer--eb1ae";
export var subTitle = "HireDeveloper-module--subTitle--4ffc3";
export var techCard = "HireDeveloper-module--techCard--59a9f";