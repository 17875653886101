// extracted by mini-css-extract-plugin
export var bg = "Responsibilities-module--bg--18461";
export var cards = "Responsibilities-module--cards--be382";
export var content = "Responsibilities-module--content--55934";
export var heading = "Responsibilities-module--heading--14827";
export var innerResponsible = "Responsibilities-module--innerResponsible--0ebab";
export var points = "Responsibilities-module--points--89283";
export var responsible = "Responsibilities-module--responsible--a0154";
export var rowCards = "Responsibilities-module--rowCards--a6b4e";
export var subTitle = "Responsibilities-module--subTitle--f925a";
export var tick = "Responsibilities-module--tick--5d570";