// extracted by mini-css-extract-plugin
export var arrowDiv = "AugmentationSol-module--arrowDiv--53228";
export var arrowImg = "AugmentationSol-module--arrowImg--03233";
export var boxContent = "AugmentationSol-module--boxContent--da743";
export var btn = "AugmentationSol-module--btn--b8a12";
export var cards = "AugmentationSol-module--cards--a6b19";
export var cardsContent = "AugmentationSol-module--cardsContent--67505";
export var cardsZ = "AugmentationSol-module--cardsZ--e6018";
export var djangoArrowImg = "AugmentationSol-module--djangoArrowImg--ec151";
export var estabBtn = "AugmentationSol-module--estabBtn--154d5";
export var explorrBtn = "AugmentationSol-module--explorrBtn--754f5";
export var finImg = "AugmentationSol-module--finImg--f49bd";
export var heading = "AugmentationSol-module--heading--45af0";
export var processSteps = "AugmentationSol-module--processSteps--a08b2";
export var product1 = "AugmentationSol-module--product1--5496c";
export var ser = "AugmentationSol-module--ser--8e191";
export var soft = "AugmentationSol-module--soft--dc61f";
export var softwareTabs = "AugmentationSol-module--softwareTabs--f7b33";
export var tabImg = "AugmentationSol-module--tabImg--83e0c";
export var tabbingCards = "AugmentationSol-module--tabbingCards--e8fd5";