import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./DedicatedTeam.module.scss"

const DedicatedTeam = ({ strapiData }) => {
  return (
    <div className={styles.dedicatedTeam}>
      <Container>
        <div>
          <p className={styles.title}>{strapiData?.title}</p>
          <h2 className={styles.subTitle}>{strapiData?.subTitle}</h2>
          <Row className={`${styles.rowCards} gap-30`}>
            {strapiData?.cards?.map((v, i) => (
              <Col lg={6} key={i} className={`${styles.cards}`}>
                <div>
                  <lottie-player
                    autoplay
                    loop
                    src={v?.image1[0]?.localFile?.publicURL}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                  <h3>{v?.title}</h3>
                  <p>{v?.subTitle}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default DedicatedTeam
