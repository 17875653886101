import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Responsibilities.module.scss"

const Responsibilities = ({ strapiData }) => {
  return (
    <div className={styles.responsible}>
      <Container className={styles.innerResponsible}>
        <div className={styles.content}>
          <p
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          ></p>
          <h2 className={styles.heading}>{strapiData?.subTitle}</h2>
        </div>
        <div className={styles.rowCards}>
          {strapiData?.cards?.map((v, i) => (
            <div lg={6} key={i} className={styles.cards}>
              <h3>{v?.title}</h3>
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_e94ee3887e.webp"
                alt="background"
                loading="lazy"
                className={styles.bg}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: v?.description?.description,
                }}
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Responsibilities
