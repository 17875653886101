import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import AugmentationSol from "../components/dedicated-revamp/AugmentationSol"
import Banner from "../components/dedicated-revamp/Banner"
import Responsibilities from "../components/dedicated-revamp/Responsibilities"
import MainLayout from "../layouts/MainLayout"
import Faqs from "../components/common/Faqs2"
import Process from "../components/dedicated-revamp/Process"
import HireDeveloper from "../components/dedicated-revamp/HireDeveloper"
import AugmentationSolMobile from "../components/dedicated-revamp/AugmentationSolMobile"
import DedicatedTeam from "../components/dedicated-revamp/DedicatedTeam"

const Dedicated = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const banner = data?.strapiPage?.sections[0]
  const dedicatedTeam = data?.strapiPage?.sections[1]
  const responsibilities = data?.strapiPage?.sections[2]
  const augmentationSol = data?.strapiPage?.sections[3]
  const process = data?.strapiPage?.sections[4]
  const faqs = data?.strapiPage?.sections[5]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={banner} />
      <DedicatedTeam strapiData={dedicatedTeam} />
      <Responsibilities strapiData={responsibilities} />
      {!isMobile && !isTablet ? (
        <AugmentationSol strapiData={augmentationSol} />
      ) : (
        <AugmentationSolMobile strapiData={augmentationSol} />
      )}
      <HireDeveloper />
      <Process strapiData={process} />
      <Faqs strapiData={faqs} staffFaq={true}/>
      <ContactSales />
    </MainLayout>
  )
}

export const query = graphql`
  query dedicatedRevamp {
    strapiPage(slug: { eq: "dedicated-software-development-teams" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default Dedicated

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  // let headSchema = []
  // const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
  //   return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  // })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        // schemas={headSchema}
      />
    </>
  )
}
